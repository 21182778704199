<template>
  <div class="container">
    <div v-if="doesCarExists">
      <div class="columns">
        <div class="column is-full">
          <div>
            <!-- Dane samochodu -->
            <div class="level">
              <div class="level-left">
                <!-- <div class="level-item">Dane samochodu {{ this.$route.params.id }}</div> -->
              </div>
              <div class="level-right">
                <div class="level-item">
                  <b-button
                    icon-left="cash"
                    type="is-primary"
                    @click="showModal = !showModal"
                    rounded
                  >
                    Wyceń pojazd
                  </b-button>
                </div>
              </div>
            </div>
            <car-details :car="car.car_details"></car-details>
            <!-- PRZEGLĄD & UBEZPIECZENIE -->
            <div class="columns is-full">    
                <div class="column is-6 ">
                    <div class="box">
                      <div class="level">
                        <div class="level-right"><strong>Badanie techniczne samochodu</strong></div>
                      </div>
                      <hr/>
                      <div v-if="details.technical_diagnose && details.technical_diagnose.length > 0">
                        Data badania: <strong>{{details.technical_diagnose[0].technical_diagnose_start}}</strong> <br/>
                        Termin następnego badania: <strong>{{details.technical_diagnose[0].technical_diagnose_expire}}</strong>
                      </div>
                      <div v-else><strong>Brak badań technicznych w naszym systemie!</strong></div>
                    </div>
                </div>
                <div class="column is-6" >
                    <div class="box">
                      <div class="level">
                        <div class="level-right"><strong>Ubezpieczenie samochodu</strong></div>
                      </div>
                      <hr/>
                      <div v-if="details.insurence && details.insurence.length > 0">
                        Ubezpieczyciel: <strong>{{details.insurence[0].insurence_company}}</strong> <br/>
                        Data rozpoczęcia umowy: <strong>{{details.insurence[0].insurence_start}}</strong> <br/>
                        Data końca umowy: <strong>{{details.insurence[0].insurence_expire}}</strong>
                      </div>
                      <div v-else><strong>Brak umowy ubezpieczeniowej w naszym systemie!</strong></div>
                    </div>
                </div>  
            </div>

            <hr />
            <!-- ------------------------------------------------ -->

            <!-- Historia eksploatacji -->
            <!-- <div v-if="hasHistory" class="pt-3">
              <car-history :carHistory="carHistory"></car-history>
            </div>
            <div v-else>Brak historii eksploatacji</div>
            <hr /> -->
            <!-- ------------------------------------------------ -->

            <!-- Galeria zdjęć pojazdu -->
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <h1 class="title is-4">Galeria</h1>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <b-button
                    icon-left="image-multiple"
                    type="is-primary"
                    @click="showUpload = true"
                    rounded
                    >Dodaj zdjęcia pojazdu</b-button
                  >
                </div>
              </div>
            </div>
            <div
              class="block"
              v-if="details && details.photos && details.photos.length > 0"
            >
              <gallery :items="photos" @delete="deletePhoto" />
            </div>
            <div class="block" v-else>Brak zdjęć samochodu</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
    <!-- -->
    <b-modal v-model="showUpload">
      <upload
        @upload="uploadPhotos"
        :loadingVal="loading"
        :val="index"
        @startUpload="loading = true"
      />
    </b-modal>
    <!-- Okno modalne przy wycenie -->
    <b-modal v-model="showModal">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Informacja</div>
        </div>
        <div class="card-content has-text-centered">
          <p>Wyceny pojazdu dokonują pracownicy AppCars.</p>
          <p>Wiadomośc z wyceną dostaniesz na adres email w przeciągu X dni.</p>
        </div>
        <div class="card-footer">
          <a  @click="showModal=false" class="card-footer-item">Wyceń</a>
          <a  @click="showModal=false" class="card-footer-item">Anuluj</a>          
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CarDetails from "@/components/car-data/CarDetails";
/* import CarHistory from "@/components/car-data/CarHistory"; */
import Gallery from "@/components/photo/Carousel";
import UploadPhotos from "@/components/photo/UploadPhotos";
// import CarCreator from "@/components/car-data/CarCreator";

export default {
  name: 'Car',
  components: {
    "car-details": CarDetails,
    /* "car-history": CarHistory, */
    gallery: Gallery,
    upload: UploadPhotos,
    // "car-creator": CarCreator,
  },

  created() {
    const id = Number.parseInt(this.$route.params.id);
    this.$store.dispatch("clientCars/getCarDetails", id);
    this.$store.dispatch("clientCars/getCarHistory", id);
  },

  data() {
    return {
      showModal: false,
      showUpload: false,
      loading: false,
      index: 0,
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    deletePhoto(e) {
      //console.log("e", e);
      this.$store
        .dispatch("clientCars/deleteCarPhoto", e)
        .then((res) => {
          if (res.status == 200)
            this.$buefy.toast.open({
              duration: 5000,
              message: `Zdjęcie zostało usunięte, odśwież stronę.`,
              position: "is-top",
              type: "is-dark",
            });
          else {
            this.$buefy.toast.open({
              duration: 5000,
              message: `Błąd. Zdjęcie nie zostało usunięte `,
              position: "is-top",
              type: "is-danger",
            });
          }
        })
        .catch((err) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Błąd. Zdjęcie nie zostało usunięte ` + err,
            position: "is-top",
            type: "is-danger",
          });
        });
    },
    uploadPhotos(e) {
      //console.log(e);
      const files = e;
      for (let i in files) {
        this.loading = true;
        this.index = i;
        this.$store
          .dispatch("clientCars/uploadCarPhoto", {
            carId: this.$route.params.id,
            file: files[i],
          })
          .then(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: `Zdjęcie zostało dodane. Odśwież stronę.`,
              position: "is-top",
              type: "is-primary",
            });
            this.loading = false;
          })
          .catch((err) => {
            console.error(err);
          });
      }
      this.showUpload = false;
    },
  },

  computed: {
    car() {
      return this.$store.getters["clientCars/getCarById"](
        this.$route.params.id
      );
    },

    details() {
      return this.$store.getters["clientCars/getDetails"];
    },

    photos() {
      if (this.details && this.details.photos.length == 0) return [];
      return this.details.photos.map((photo) => {
        //console.log(photo, "#");
        return {
          image: photo.url,
          name: photo.name,
        };
      });
    },

    carHistory() {
      return this.$store.getters["clientCars/getCarHistory"](
        this.$route.params.id
      ).map((h) => h.history);
    },

    doesCarExists() {
      return this.car !== undefined;
    },

    hasHistory() {
      return (
        this.carHistory != undefined &&
        this.carHistory &&
        this.carHistory.length > 0
      );
    },
  },
};
</script>
<style lang="scss" scoped>
</style>