<template>
  <div class="container box tile is-6">
    <div class="tile is-parent is-vertical">
      <div class="tile">
        <b-upload
          expanded
          v-model="dropFiles"
          multiple
          drag-drop
          accept="image/jpg, image/jpeg"
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"> </b-icon>
              </p>
              <p>Umieść tutaj plik .jpg .jpeg</p>
            </div>
          </section>
        </b-upload>
      </div>
      <div class="box" v-if="loading">
        <b-progress type="is-primary" size="is-small">
          {{ val }} / {{ this.dropFiles.length }}
        </b-progress>
      </div>
      <div class="tile">
        <ul>
          <li v-for="(file, index) in dropFiles" :key="index">
            {{ file.name }} &nbsp;
          </li>
        </ul>
        <br />
      </div>

      <div class="tile">
        <b-button type="is-primary" expanded @click="upload">
          Załącz zdjęcie
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadPhotos",
  props: {
    loadingVal: Boolean,
    val: String,
  },
  data() {
    return {
      dropFiles: [],
      loading: this.loadingVal || false,
    };
  },
  methods: {
    upload() {
      this.$emit("startUpload");
      this.$emit("upload", this.dropFiles);
    },
  },
};
</script>

<style scoped lang="scss">
.upload {
  position: relative;
  width: 100%;
}

li {
  word-break: break-all;
}
</style>