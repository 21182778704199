<template>
  <div class="gallery">
    <b-carousel
      :autoplay="false"
      with-carousel-list
      :indicator="false"
      :overlay="gallery"
      @click="switchGallery(true)"
    >
      <b-carousel-item v-for="(item, i) in items" :key="i">
        <figure class="image">
          <img :src="item.image" />
        </figure>
        <b-button
          type="is-dark"
          class="delete-button"
          @click="confirmDelete(item.name)"
          rounded
        >
          <b-icon icon="delete" size="is-small"> </b-icon>
        </b-button>
      </b-carousel-item>

      <span
        v-if="gallery"
        @click="switchGallery(false)"
        class="modal-close is-large"
      />
      <template #list="props">
        <b-carousel-list
          v-model="props.active"
          :data="items"
          v-bind="al"
          @switch="props.switch($event, false)"
          as-indicator
        />
      </template>
      <template #overlay>
        <div class="has-text-centered has-text-white"></div>
      </template>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: {
    items: Array,
  },
  data() {
    return {
      gallery: false,
      al: {
        hasGrayscale: true,
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 4,
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 6,
          },
        },
      },
    };
  },
  methods: {
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        document.documentElement.classList.add("is-clipped");
      } else {
        document.documentElement.classList.remove("is-clipped");
      }
    },
    confirmDelete(name) {
      this.$buefy.dialog.confirm({
        message: `Czy usunąć zdjęcie (${name})?`,
        confirmText: "Usuń",
        cancelText: "Anuluj",
        onConfirm: () => {
          this.$emit("delete", name);
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.gallery {
  position: relative;
  left: 10%;
  width: 80%;
}
.delete-button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  opacity: 0.5;
  &:hover {
    transition: 0.5s ease;
    opacity: 1;
  }
}
</style>