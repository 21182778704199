<template>
    <div class="box">
        <h1 class="title is-4">Dane samochodu</h1>
        <hr>
        <div class="columns">
            <div class="column">
                <div>Marka: {{ car.serie.model.brand.name }}</div>
                <div>Model: {{ car.serie.model.name }}</div>
                <div>Generacja: {{ car.generation }}</div>
                <div>Seria: {{ car.serie.name }}</div>
                <div>Silnik: {{ car.engine.name }}</div>
            </div>
            <div class="column">
                <div>Tapicerka: {{ car.upholstery_type.name }}, {{ car.upholstery_color.name }} </div>
                <div>Lakier: {{ car.paint_type.name }}, {{ car.paint_color.name }}</div>
                <div>Felgi: {{ car.rims_type.name }}</div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        car: Object
    }
}
</script>
<style>
</style>